@charset "UTF-8";

@keyframes loading_01 {
    0% {
        opacity: 1;
    }
    15% {
        opacity: 1;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes loading_02 {
    0% {
        opacity: 0;
    }
    23% {
        opacity: 0;
    }
    33% {
        opacity: 1;
    }
    39% {
        opacity: 1;
    }
    43% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes loading_03 {
    0% {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    85% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.loading {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    z-index: 1000000;

    &-image {
        animation-name: loading_01;
        animation-duration: 5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
    }

    &-animation {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
    }

    &-logo {
        animation-name: loading_02;
        animation-duration: 5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
        opacity: 0;

        &:nth-of-type(2) { animation-delay: 0.30s; }
        &:nth-of-type(3) { animation-delay: 0.55s; }
        &:nth-of-type(4) { animation-delay: 0.74s; }
        &:nth-of-type(5) { animation-delay: 0.88s; }
        &:nth-of-type(6) { animation-delay: 0.98s; }
        &:nth-of-type(7) { animation-delay: 1.05s; }
        &:nth-of-type(8) { animation-delay: 1.10s; }
    }

    &-text {
        animation-name: loading_03;
        animation-duration: 5s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        animation-fill-mode: forwards;
    }
}

#top {
    visibility: hidden;
    
    .p-footer-wrapper {
        padding-top: 0;
        background-image: none;
    }
}

.top {
    &-mv {
        &-last {
            width: 100%;
            padding-top: 450px;
            // padding-top: 360px;
            background-image: url(../images/common/pattern-04.png);
            background-position: top center;
            background-repeat: repeat;
            background-size: 100px 120px;

            @include view-at(sp) {
                padding-top: 345px;
                background-size: 50px 60px;
            }
        }
    }

    &-section {
        padding-top: 90px;
        background-image: url(../images/common/pattern-03.png);
        background-position: top center;
        background-repeat: repeat-x;
        background-size: 100px 90px;

        @include view-at(sp) {
            padding-top: 45px;
            background-size: 50px 45px;
        }
    }
}