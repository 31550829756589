
/* --------------------------
color
-------------------------- */

$color-text: #000000;
$color-text-snow: #d1d1d1;
$color-text-light: #595757;
$color-text-brown: #231815;

$color-red: #cf121b;
$color-blue: #103674;
$color-yellow: #fff000;
$color-green: #00654d;
$color-ocher: #a57823;
$color-white: #fff;
$color-white-dark: rgba(255, 255, 255, .85);
$color-white-light: rgba(255, 255, 255, .5);
$color-white-snow: rgba(255, 255, 255, 0.15);
$color-black: #000;
$color-black-dark: rgba(0, 0, 0, .85);
$color-black-light: rgba(0, 0, 0, .5);
$color-black-snow: rgba(0, 0, 0, .15);

$color-gray: #ccc;
$color-gray-dark: #484B44;
$color-gray-light: #eee;
$color-gray-snow: #F5F5F5;
$color-border: #777777;

/* --------------------------
spacing
-------------------------- */
$space: 100px, 70px, 50px, 35px;
$space-tab: 80px, 60px, 40px, 20px;
$space-sp: 60px, 40px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$font-size-sp: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height-sp: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$black: 900;
$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;
$thin: 100;
$normal: 300;

/* --------------------------
contents width  
-------------------------- */
$contents-width: 1040px;
$contents-middle-width: 840px;
$contents-narrow-width: 640px;
$contents-padding: 20px;
$contents-padding-sp: 15px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  menu,
  header,
  footer,
  modal
);
