.p-cta {
  text-align: center;

  a {
    margin: auto;
  }

  h3 {
    color: $color-green;
    font-size: 29px;
    line-height: 1.34;
    margin-top: 20px;

    @include view-at(tab) {
      font-size: 26px;
    }

    @include view-at(sp) {
      font-size: 18px;
    }
  }
}