@charset "UTF-8";

.vision {
    &-copy {
        span {
            position: relative;
            display: inline-block;
            font-size: rem(30px);
            line-height: 1.7;
            // padding: 0 1em;
            margin: 120px auto;

            @include view-at(sp) {
                font-size: rem(24px);
                font-feature-settings: "palt" 1;
                letter-spacing: 0.1em;
                margin: 80px auto;
            }

            // &::before {
            //     content: '「';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     display: inline-block;
            // }
            // &::after {
            //     content: '」';
            //     position: absolute;
            //     right: 0;
            //     bottom: 0;
            //     display: inline-block;
            // }
        }
    }

    &-concept {
        position: relative;
        display: flex;
        justify-content: center;
        z-index: 1;

        .left,
        .right {
            position: relative;
            @include font-iroha;
            font-size: rem(64px);
            padding: 80px;

            @include view-at(sp) {
                font-size: rem(42px);
                padding: 60px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                z-index: -1;
            }
        }
        .left {
            margin-right: -40px;

            @include view-at(sp) {
                margin-right: -30px;
            }

            &::before {
                background-color: rgba(207, 18, 27, .25);
            }
        }
        .right {
            &::before {
                background-color: rgba(255, 212, 0, .25);
            }
        }

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-item {
            &.center {
                width: 50%;

                @include view-at(sp) {
                    width: 100%;
                    order: 1;
                }
            }
            &.left {
                width: 23%;

                @include view-at(sp) {
                    width: 48%;
                    order: 2;
                }
            }
            &.right {
                width: 23%;

                @include view-at(sp) {
                    width: 48%;
                    order: 3;
                }
            }
        }

        &-detail {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            padding: 20px;
            border: 2px solid $color-white;

            @include view-at(sp) {
                padding: 10px;
                margin-top: 10px;
            }

            &.left {
                background-color: rgba(207, 18, 27, .1);
                border-color: rgba(207, 18, 27, .25);
            }
            &.right {
                background-color: rgba(255, 212, 0, .1);
                border-color: rgba(255, 212, 0, .25);
            }

            span {
                line-height: 2;

                @include view-at(sp) {
                    font-size: rem(12px);
                    line-height: 1.7;
                }
            }
        }
    }

    &-unit {
        position: relative;
        padding: 40px;
        background-color: $color-white;
        border: 4px solid $color-ocher;
        z-index: 1;

        @include view-at(sp) {
            padding: 20px 15px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 4px;
            display: inline-block;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            border: 1px solid $color-ocher;
            z-index: -1;
        }
    }

    &-seven {
        width: 70%;
        margin: auto;
        counter-reset: seven;

        @include view-at(sp) {
            width: 100%;
            padding: 0 10px;
        }

        li {
            position: relative;
            font-size: rem(16px);
            line-height: 1.5;
            padding-left: 20px;
            counter-increment: seven;

            @include view-at(sp) {
                display: flex;
                flex-wrap: wrap;
                font-size: rem(14px);
            }

            &:before {
                content: counter(seven)" .";
                position: absolute;
                left: 0;
            }

            &:not(:first-child) {
                padding-top: 15px;

                @include view-at(sp) {
                    padding-top: 10px;
                }
            }

            .title {
                @include view-at(sp) {
                    width: 100%;
                }
            }
            .arrow {
                padding: 0 10px;

                @include view-at(sp) {
                    width: calc(1em + 10px);
                    padding-left: 0;
                }
            }
            .detail {
                @include view-at(sp) {
                    width: calc(100% - 1em - 20px);
                }
            }
        }
    }
}