/* --------------------------
テーブル
-------------------------- */

.c-table {
    width: 100%;
    font-size: rem(14px);
    line-height: 1.7;
    border-top: 1px solid $color-black;

    thead {
        tr {
            th {
                padding: 15px;
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid $color-black;
        }

        th {
            width: 25%;
            padding: 25px 0;
            word-break: keep-all;
            font-weight: $normal;
            vertical-align: top;

            @include view-at(sp) {
                width: 100%;
                display: block;
                padding: 10px 0;
                background-color: $color-gray-snow;
            }
        }

        td {
            width: 75%;
            padding: 25px 0;
            vertical-align: top;

            @include view-at(sp) {
                width: 100%;
                display: block;
                padding: 10px 0;
                border-top: 1px solid $color-black;
            }
        }
    }

    &--border {
        border-top: 1px solid $color-gray;
        border-collapse: collapse;

        thead {
            tr {
                border-bottom: 1px solid $color-gray;

                th {
                    font-weight: $bold;
                    padding: 20px 30px 20px 0;
                }
            }
        }

        tbody {
            tr {
                border: none;
            }

            th,td {
                padding: 15px;
                border: 1px solid $color-gray;

                @include view-at(sp) {
                    padding: 10px;
                }
            }

            th {
                width: 25%;
                background-color: $color-gray-light;

                @include view-at(sp) {
                    width: 100%;
                    border-top: none;
                    border-bottom: none;
                }
            }

            td {
                width: 75%;

                @include view-at(sp) {
                    width: 100%;
                }
            }
        }
    }
}
