/* --------------------------
ヘッダー
-------------------------- */

.p-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    z-index: 1000;
    padding-bottom: 90px;
    background-image: url(../images/common/pattern-02.png);
    background-position: bottom center;
    background-repeat: repeat-x;
    background-size: 100px 90px;

    @include view-at(sp) {
        padding-bottom: 45px;
        background-size: 50px 45px;
    }

    &-inner {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        width: 1040px;
        height: 100%;
        max-width: 100%;
        padding: 41px 20px;
        margin: 0 auto;

        @include view-at(sp) {
            width: 100%;
            padding: 10px 15px;
        }
    }

    &-logo {
        a {
            display: inline-block;
        }

        img {
            width: 372px;

            @include view-at(sp) {
                width: 192px;
            }
        }
    }

    &-menu {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
    }
}

