/* --------------------------
テキスト装飾
-------------------------- */

.u-text-accent {
  color: $color-ocher;
}

.u-text-important {
  color: $color-red;
}

.u-text-inverse {
  color: $color-white;
}

.u-text-bold {
  font-weight: $bold;
}

.u-text-normal {
  font-weight: $normal;
}

.u-text-snow {
  color: $color-text-snow;
}

.u-text-light {
  color: $color-text-light;
}

.u-text-note {
  font-size: rem(12px);
  line-height: 1.42;
  letter-spacing: 0;
  text-align: justify;
  text-justify: inter-ideograph;
}

.u-text-highlight {
  background: linear-gradient(transparent 70%, $color-yellow 70%);
}

.u-text-underline {
  text-decoration: underline;
}

.u-text-x-large {
  font-size: rem(30px);

  @include view-at(sp) {
    font-size: rem(24px);
  }
}

.u-text-large {
  font-size: rem(24px);

  @include view-at(sp) {
    font-size: rem(20px);
  }
}

.u-text-bit-large {
  font-size: rem(20px);

  @include view-at(sp) {
    font-size: rem(18px);
  }
}

.u-text-medium {
  font-size: rem(16px);

  @include view-at(sp) {
    font-size: rem(16px);
  }
}

.u-text-default {
  font-size: rem(14px);

  @include view-at(sp) {
    font-size: rem(14px);
  }
}

.u-text-small {
  font-size: rem(12px);

  @include view-at(sp) {
    font-size: rem(12px);
  }
}

.u-text-x-small {
  font-size: rem(10px);

  @include view-at(sp) {
    font-size: rem(10px);
  }
}
