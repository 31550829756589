/* --------------------------
背景色
-------------------------- */
$bg-green-light: #efecd6;

.u-bg-black {
  background-color: $color-black;
}

.u-bg-gray {
  background-color: $color-gray;
}

.u-bg-gray-snow {
  background-color: $color-gray-snow;
}

.u-bg-white {
  background-color: $color-white;
}

.u-bg-green {
  background-color: $color-green;
}

.u-bg-green-light {
    background-color: $bg-green-light;
  }

.u-bg-blue {
  background-color: $color-blue;
}

.u-bg-red {
  background-color: $color-red;
}
