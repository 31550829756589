/* --------------------------
エクストラナビ
-------------------------- */

.p-exnav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: 30px;

    @include view-at(sp) {
        margin-right: 20px;
    }

    &-button {
        margin-left: 15px;

        a {
            display: inline-block;
        }

        img {
            max-width: 30px;
            max-height: 30px;
        }
    }
}
