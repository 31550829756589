/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
    position: relative;
    display: block;
    height: 100%;
    padding: 0;
    z-index: index($z, menu);

    &-trigger,
    &-trigger span {
        display: block;
        transition: all .2s;
        box-sizing: border-box;
    }

    &-trigger {
        position: relative;
        width: 44px;
        height: 44px;

        @include view-at(sp) {
            width: 40px;
        }

        &-label {
            position: absolute;
            left: 0;
            right: 0;
            top: 18px;
            display: block;
            width: 100%;
            height: auto;
            color: $color-text;
            font-size: 10px;
            text-align: center;
            letter-spacing: 0.1em;
            margin: auto;
            background-color: transparent;

            @include view-at(tab) {
                color: $color-text;
            }
        }
    }

    &-trigger span {
        position: absolute;
        right: 0;
        width: 44px;
        height: 2px;
        background-color: $color-text;

        @include view-at(sp) {
            width: 40px;
        }
    }

    &-trigger span:nth-of-type(1) {
        top: 10px;
    }

    &-trigger span:nth-of-type(2) {
        top: 21px;
    }

    &-trigger span:nth-of-type(3) {
        top: 32px;
    }

    &-trigger.active span:nth-of-type(1) {
        -webkit-transform: translateY(11px) rotate(-45deg);
        transform: translateY(11px) rotate(-45deg);
    }

    &-trigger.active span:nth-of-type(2) {
        opacity: 0;
    }

    &-trigger.active span:nth-of-type(3) {
        -webkit-transform: translateY(-11px) rotate(45deg);
        transform: translateY(-11px) rotate(45deg);
    }
}
