/* --------------------------
フッター
-------------------------- */

.p-fnav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 1040px;
    height: 100%;
    max-width: 100%;
    padding: 35px 20px;
    margin: 0 auto;

    @include view-at(sp) {
        width: 100%;
        padding: 15px;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &-item {
        position: relative;
        text-align: center;
        padding: 0 20px;

        @include view-at(sp) {
            width: 50%;
            padding: 10px 0;
        }
        
        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $color-text;

            .en {
                font-size: rem(20px);
                font-weight: $medium;
                margin-bottom: 10px;

                @include view-at(sp) {
                    font-size: rem(16px);
                    margin-bottom: 8px;
                }
            }

            .ja {
                font-size: rem(13px);
                font-weight: $regular;

                @include view-at(sp) {
                    font-size: rem(11px);
                }
            }
        }

        &.current {
            a {
                color: $color-ocher;
                pointer-events: none;
            }
        }
    }
}
