@charset "UTF-8";

.business {
    &-point {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(18px * 3.4 + 100px);
        padding: 20px 15px;
        background-color: $color-ocher;

        @include view-at(sp) {
            height: auto;
            padding: 30px 15px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: 4px;
            display: inline-block;
            width: calc(100% - 8px);
            height: calc(100% - 8px);
            border: 1px solid $color-white;
        }

        &-main {
            color: $color-white;
            font-size: rem(18px);
            text-align: center;
            margin-bottom: 0;
        }
    }
}