/* --------------------------
見出し
-------------------------- */
.c-h2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-text;
    margin-bottom: 30px;

    .en {
        font-size: rem(23px);
        font-weight: $medium;
        margin-bottom: 10px;

        @include view-at(sp) {
            font-size: rem(20px);
            margin-bottom: 8px;
        }
    }

    .ja {
        font-size: rem(13px);
        font-weight: $regular;

        @include view-at(sp) {
            font-size: rem(12px);
        }
    }
}

.c-h3 {
    position: relative;
    display: flex;
	justify-content: center;
	align-items: center;
    font-size: rem(20px);
    font-weight: $medium;
    line-height: 1.5;
	text-align: center;
    margin-bottom: 40px;

    @include view-at(sp) {
        margin-bottom: 30px;
    }

    &::before,
    &::after {
        content: '';
        width: 70px;
        height: 2px;
        background-color: $color-ocher;
    }

    &::before {
        margin-right: 20px;

        @include view-at(sp) {
            margin-right: 10px;
        }
    }
    &::after {
        margin-left: 20px;

        @include view-at(sp) {
            margin-left: 10px;
        }
    }
}

.c-h4 {
    position: relative;
    font-size: rem(18px);
    font-weight: $medium;
    line-height: 1.5;
    padding-left: 20px;
    margin-bottom: 20px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 8px;
        height: 100%;
        background-color: $color-ocher;
        border-radius: 1px;
    }
}

.c-h5 {
    font-size: rem(16px);
    font-weight: $medium;
    line-height: 1.7;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 2px solid $color-text-snow;
}

.c-h6 {
    font-size: rem(14px);
    font-weight: $medium;
    line-height: 1.7;
    margin-bottom: 20px;
}

.c-h--center-line {
    @include center-line();
    margin-bottom: 5px;
}