/* --------------------------
フッター
-------------------------- */

.p-footer {
    &-wrapper {
        position: relative;
        width: 100%;
        background-color: $color-white;
        z-index: 100;
        padding: 90px 0 30px;
        background-image: url(../images/common/pattern-02.png);
        background-position: top center;
        background-repeat: repeat-x;
        background-size: 100px 90px;

        @include view-at(sp) {
            padding: 45px 0 15px;
            background-size: 50px 45px;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 30px;
            background-image: url(../images/common/pattern-01.png);
            background-position: top center;
            background-repeat: repeat-x;
            background-size: 100px 30px;

            @include view-at(sp) {
                height: 15px;
                background-size: 50px 15px;
            }
        }
    }

    &-copyright {
        color: $color-text-brown;
        font-size: rem(13px);
        text-align: center;
        line-height: 1;
        padding: 13px 10px;

        @include view-at(sp) {
            font-size: rem(11px);
        }
    }
}
