/* --------------------------
ボタン
-------------------------- */
.c-button {
    position: relative;
    display: inline-block;
    width: auto;
    min-width: 168px;
    color: $color-white;
    font-size: rem(14px);
    line-height: 1;
    letter-spacing: 0.05em;
    text-decoration: none;
    text-align: center;
    padding: 16px 24px;
    background-color: $color-ocher;
    border: 2px solid $color-ocher;
    cursor: pointer;

    &--small {
        font-size: rem(12px);
        padding: 10px 20px;
    }

    &--medium {
        min-width: 200px;
    }

    &--large {
        min-width: 320px;
        font-size: rem(16px);
        padding: 20px 40px;

        @include view-at(sp) {
            min-width: auto;
            width: 100%;
        }
    }

    &--border {
        color: $color-ocher;
        background-color: $color-white;
    }

    &--arrow {
        padding-right: 50px;
        
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 20px;
            width: 8px;
            height: 8px;
            border: 0px;
            border-top: solid 1px $color-white;
            border-right: solid 1px $color-white;
            transform: rotate(45deg);
            margin-top: -3px;
        }
    }
}


