html {
  font-size: 14px;
}

body {
  font-family: 'source-han-sans-japanese', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', '游ゴシック', 'Yu Gothic', '游ゴシック体', YuGothic, 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  font-weight: 300;
  color: $color-text;
  font-size: rem(14px);
  text-align: justify;
  text-justify: inter-ideograph;
}

a {
  color: $color-ocher;
  text-decoration: none;
  transition: all .3s;

  &:hover {
    opacity: 0.6;
  }
}

p {
  margin-bottom: 1em;
  font-weight: $normal;
  line-height: 1.7;
  text-align: justify;
  text-justify: inter-ideograph;

  &:last-child {
    margin-bottom: 0;
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

.main {
  padding-top: 230px;

  @include view-at(sp) {
    padding-top: 109px;
  }
}

