/* --------------------------
源ノ角ゴシック JP Normal
-------------------------- */
@mixin font-source-normal {
    font-family: 'source-han-sans-japanese', sans-serif;
    font-weight: 300;
    font-style: normal;
}

/* --------------------------
源ノ角ゴシック JP Regular
-------------------------- */
@mixin font-source-regular {
    font-family: 'source-han-sans-japanese', sans-serif;
    font-weight: 400;
    font-style: normal;
}

/* --------------------------
源ノ角ゴシック JP Medium
-------------------------- */
@mixin font-source-medium {
    font-family: 'source-han-sans-japanese', sans-serif;
    font-weight: 500;
    font-style: normal;
}

/* --------------------------
いろはゴシック
-------------------------- */
@mixin font-iroha {
    font-family: 'iroha-23kaede-stdn', sans-serif;
    font-weight: 400;
    font-style: normal;
}